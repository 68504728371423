<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th class="border-top-0">
							{{ translate('product') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('sku') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('quantity') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('total_tickets') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('subtotal') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('tax') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('expenses') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							<img
								v-if="item.attributes.image !== null"
								:src="item.attributes.image"
								class="responsive block-inline"
								style="max-width:32px">
							<div
								v-if="item.attributes.image === null"
								style="width:32px; display:inline-block;" />
							{{ translate(item.attributes.code_name) }} {{ item.attributes.is_free ? `(${translate('free')})` : '' }}
						</td>
						<td class="align-middle">
							{{ item.attributes.group }}
						</td>
						<td class="align-middle text-center">
							{{ item.attributes.qty }}
						</td>
						<td class="align-middle text-center">
							{{ item.attributes.tickets }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.subtotal }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.tax }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.expenses }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total }}
						</td>
					</tr>
					<tr class="text-right">
						<td colspan="2">
							<b />
						</td>
						<td class="text-center">
							<b>{{ totals.qty }}</b>
						</td>
						<td class="text-center">
							<b>{{ totals.tickets }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.subtotal }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.tax }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.expenses }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.total }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import {
	MDY_FORMAT as mdyFormat,
} from '@/settings/Dates';
import {
	Report, Grids, PaymentMethods, Countries, Products,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import Sales from '@/util/Report';

export default {
	name: 'SalesProducts',
	messages: [Report, Grids, PaymentMethods, Countries, Products],
	mixins: [FiltersParams],
	data() {
		return {
			sales: new Sales(),
		};
	},
	computed: {
		loading() {
			return !!this.sales.data.loading;
		},
		errors() {
			return this.sales.data.errors;
		},
		data() {
			try {
				const { data } = this.sales.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.sales.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.$parent.$parent.$parent.rangeStart = '';
		this.$parent.$parent.$parent.rangeEnd = '';
		this.sales.getSalesByProduct().then(() => {
			const { start, end } = this.sales.data.response.data.meta.period_dates;
			this.$parent.$parent.$parent.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.$parent.$parent.$parent.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			this.sales.getSalesByProduct(options);
		},
	},
};
</script>
